import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { ClassnamesArgument } from 'types/base';

import CSS from './Page.module.css';

type Props = {
    children: ReactNode;
    className?: ClassnamesArgument;
};

export default function Page({ children, className }: Props) {
    return <div className={classnames(CSS.Page, className)}>{children}</div>;
}
